import React from 'react';
import { NavLink } from 'react-router-dom';

const NavItem = ({ to, exact, children, onClick }) => {
  return (
    <NavLink
      to={to}
      exact={exact ? 'true' : 'false'}
      className={({ isActive }) =>
        `w-full text-white px-4 py-3 text-sm ml-0 font-medium md:mx-0 md:my-0 block border-b transition-colors duration-300 ${
          isActive ? 'bg-gray-900 border-amber-600' : 'bg-gray-950 border-gray-900 hover:border-amber-400'
        }`
      }
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};

export default NavItem;
