import React from 'react';
import packageJson from '../../package.json';

const Footer = () => {
  return (
    <footer className="bg-gray-950 text-white py-4 fixed bottom-0 left-0 w-full">
      <div className="container mx-auto flex flex-row justify-center gap-4 items-center text-center text-xs font-light">
        <a href="https://menuplus.online">2024 Menuplus <span className='border-l-4 border-x-amber-600 pl-2 ml-2 text-amber-600'>By Cencomer.com</span></a>
        <p>Versión: {packageJson.version}</p>
      </div>
    </footer>
  );
};

export default Footer;
