import React, { createContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
    const [orderId, setOrderId] = useState(uuidv4().slice(0, 8)); // UUID corto de 8 caracteres

    useEffect(() => {
        const savedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        const savedOrderId = localStorage.getItem('orderId') || uuidv4().slice(0, 8);
        setCartItems(savedCartItems);
        setOrderId(savedOrderId);
    }, []);

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
        localStorage.setItem('orderId', orderId);
    }, [cartItems, orderId]);

    const addToCart = (item) => {
        setCartItems(prevItems => {
            const existingItem = prevItems.find(i => i.uuid === item.uuid);
            if (existingItem) {
                return prevItems.map(i => i.uuid === item.uuid ? { ...i, quantity: i.quantity + 1 } : i);
            }
            return [...prevItems, { ...item, quantity: 1 }];
        });
    };

    const removeFromCart = (uuid) => {
        setCartItems(prevItems => prevItems.filter(item => item.uuid !== uuid));
    };

    const clearCart = () => {
        setCartItems([]);
        setOrderId(uuidv4().slice(0, 8));
    };

    const increaseQuantity = (uuid) => {
        setCartItems(prevItems => prevItems.map(item =>
            item.uuid === uuid ? { ...item, quantity: item.quantity + 1 } : item
        ));
    };

    const decreaseQuantity = (uuid) => {
        setCartItems(prevItems => prevItems.map(item =>
            item.uuid === uuid ? { ...item, quantity: Math.max(item.quantity - 1, 1) } : item
        ));
    };

    return (
        <CartContext.Provider value={{
            cartItems,
            orderId,
            addToCart,
            removeFromCart,
            clearCart,
            increaseQuantity,
            decreaseQuantity
        }}>
            {children}
        </CartContext.Provider>
    );
};
