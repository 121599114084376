import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiOutlineShoppingCart } from 'react-icons/hi';
import { CartContext } from '../context/CartContext';

const FloatingCartButton = () => {
  const { cartItems } = useContext(CartContext);
  const location = useLocation();

  const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);
  const totalPrice = cartItems.reduce((acc, item) => acc + item.quantity * item.precio, 0);

  // No renderizar el botón si estamos en la página del carrito
  if (location.pathname === '/cart') {
    return null;
  }

  return (
    <div className="fixed bottom-11 right-8 z-50">
      <Link to="/cart">
        <button className="bg-green-500 hover:bg-green-600 text-white py-3 px-6 gap-3 rounded-full shadow-lg flex items-center justify-center relative transition-all duration-300 ease-in-out">
          <div className="text-left">
            <div className="font-bold">Revisar Pedido</div>
            <div className="text-sm">Total: ${totalPrice.toLocaleString()}</div>
          </div>
          <HiOutlineShoppingCart size={32} />
          {totalItems > 0 && (
            <span className="absolute top-0 right-6 transform border-2 shadow-sm translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full h-6 w-6 flex items-center justify-center text-xs font-semibold">
              {totalItems}
            </span>
          )}
        </button>
      </Link>
    </div>
  );
};

export default FloatingCartButton;
