import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import Home from './pages/Home'; 
import Hamburguesas from './pages/Hamburguesas';
import Perros from './pages/Perros';
import Pepitos from './pages/Pepitos';
import Patacon from './pages/Patacon';
import Shawarma from './pages/Shawarma';
import Salchipapas from './pages/Salchipapas';
import Barril from './pages/Barril';
import Parrillas from './pages/Parrillas';
import Picadas from './pages/Picadas';
import Asados from './pages/Asados';
import Bebidas from './pages/Bebidas';
import CartPage from './pages/CartPage';
import { CartProvider } from './context/CartContext';
import Footer from './components/Footer';
import FloatingCartButton from './components/FloatingCartButton';

function App() {
  return (
    <CartProvider>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/hamburguesas" element={<Hamburguesas />} />
          <Route path="/perros" element={<Perros />} />
          <Route path="/pepitos" element={<Pepitos />} />
          <Route path="/patacon" element={<Patacon />} />
          <Route path="/shawarma" element={<Shawarma />} />
          <Route path="/salchipapas" element={<Salchipapas />} />
          <Route path="/barril" element={<Barril />} />
          <Route path="/parrillas" element={<Parrillas />} />
          <Route path="/picadas" element={<Picadas />} />
          <Route path="/asados" element={<Asados />} />
          <Route path="/bebidas" element={<Bebidas />} />
          <Route path="/cart" element={<CartPage />} />
        </Routes>
        <FloatingCartButton />
        <Footer />
      </Router>
    </CartProvider>
  );
}

export default App;
