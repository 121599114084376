import React from 'react';
import { Link } from 'react-router-dom';
import logo from './blanco.png';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';
import { SlMap, SlClock } from "react-icons/sl";
import { FiShare2 } from 'react-icons/fi';

const Home = () => {
    // Función para compartir la URL del sitio
    const shareSiteUrl = () => {
        if (navigator.share) {
            const url = window.location.href;
            navigator.share({ url });
        } else {
            // Si `navigator.share` no está disponible, muestra un mensaje de error o proporciona una alternativa
            console.error('La función de compartir no está disponible en este navegador.');
            // Opcional: mostrar un mensaje al usuario con una alternativa para compartir la URL
            alert('La función de compartir no está disponible en este navegador. Puedes copiar la URL y compartirla manualmente.');
        }
    };


    return (
        <div>
            <div className="bg-gray-800 text-white py-20 px-4 min-h-screen flex items-center">
                <div className="max-w-7xl mx-auto text-center">
                    <div className="bg-cover bg-center w-48 h-32 mx-auto" style={{ backgroundImage: `url(${logo})` }}></div>
                    <div className='flex flex-col gap-4 my-8'>
                        <h1 className="text-3xl font-extrabold">¡Bienvenid@s!</h1>
                        <h3 className="text-md">Descubre nuestro sabor venezolano.</h3>
                        <div className='flex items-center justify-center gap-4 py-1 rounded-lg border-t border-b border-amber-600'>
                            <SlClock size={20} />
                            <h1 className="text-md">Horario: 4:00 pm a 1:00 am</h1>
                        </div>
                    </div>
                    <div className='grid flex-col grid-cols-2 gap-2 mt-4'>
                        <Link to="/hamburguesas" className=" bg-white text-gray-800 py-2 px-6 rounded inline-block hover:bg-gray-200">Hamburguesas</Link>
                        <Link to="/perros" className=" bg-white text-gray-800 py-2 px-6 rounded inline-block hover:bg-gray-200">Perros</Link>
                        <Link to="/pepitos" className=" bg-white text-gray-800 py-2 px-6 rounded inline-block hover:bg-gray-200">Pepitos</Link>
                        <Link to="/patacon" className=" bg-white text-gray-800 py-2 px-6 rounded inline-block hover:bg-gray-200">Patacon</Link>
                        <Link to="/shawarma" className=" bg-white text-gray-800 py-2 px-6 rounded inline-block hover:bg-gray-200">Shawarma</Link>
                        <Link to="/salchipapas" className=" bg-white text-gray-800 py-2 px-6 rounded inline-block hover:bg-gray-200">Salchipapas</Link>
                        <Link to="/barril" className=" bg-white text-gray-800 py-2 px-6 rounded inline-block hover:bg-gray-200">Barril</Link>
                        <Link to="/parrillas" className=" bg-white text-gray-800 py-2 px-6 rounded inline-block hover:bg-gray-200">Parrillas</Link>
                        <Link to="/picadas" className=" bg-white text-gray-800 py-2 px-6 rounded inline-block hover:bg-gray-200">Picadas</Link>
                        <Link to="/asados" className=" bg-white text-gray-800 py-2 px-6 rounded inline-block hover:bg-gray-200">Asados</Link>
                        <Link to="/bebidas" className=" bg-white text-gray-800 py-2 px-6 rounded inline-block hover:bg-gray-200">Bebidas</Link>
                        <Link to="/cart" className=" bg-white text-gray-800 py-2 px-6 rounded inline-block hover:bg-gray-200">Carrito</Link>
                    </div>
                    <div className="flex space-x-4 items-center justify-center py-8">
                        <a href="https://maps.app.goo.gl/uw3ARhS8eVSQJG5PA" target="_blank" rel="noopener noreferrer">
                            <SlMap size={24} className="text-white hover:text-gray-500" />
                        </a>
                        {/* <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                            <FaFacebook size={24} className="text-white hover:text-gray-500" />
                        </a> */}
                        <a href="https://www.instagram.com/elchamodlacava" target="_blank" rel="noopener noreferrer">
                            <FaInstagram size={24} className="text-white hover:text-gray-500" />
                        </a>
                        <a href="https://www.tiktok.com/@elchamodelacavaa" target="_blank" rel="noopener noreferrer">
                            <FaTiktok size={24} className="text-white hover:text-gray-500" />
                        </a>
                        <button onClick={shareSiteUrl} className="text-white hover:text-gray-500">
                            <FiShare2 size={24} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
